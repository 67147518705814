<template>
  <div>
    <div class="functionstyle" v-for="(item, index) in infolist" :key="index">
      <div class="list flx">
        <!-- 名称 -->
        <div class="textstyles">{{ item.headline }}</div>
        <!-- 用户信息 -->
        <div class="flx1">
          <div class="commdatastyle" v-if="item.type == 'image'">
            <van-uploader multiple :max-count="1" :after-read="afterRead">
              <img class="imgstyle" :src="item.info" />
            </van-uploader>
          </div>
          <!-- 下拉选方式 -->
          <div class="commdatastyle" v-if="item.type == 'select'">
            <div
              :class="
                item.ismodification == false ? 'inputstyle' : 'inputstyles'
              "
              @click="item.ismodification == false ? Actionshowclick() : ''"
            >
              {{ item.info }}
            </div>
            <van-action-sheet
              v-model:show="Actionshow"
              :actions="actions"
              cancel-text="取消"
              close-on-click-action
              @cancel="onCancel"
              @select="onselect"
            />
          </div>
          <!-- 用户数据 -->
          <div class="commdatastyle" v-if="item.type == 'text'">
            <input
              ref="getFocus"
              class="inputstyle"
              v-model="item.info"
              :disabled="item.ismodification"
              :style="colors"
            />
          </div>
          <!-- 时间选择 -->
          <div class="commdatastyle" v-if="item.type == 'time'">
            <div
              :class="
                item.ismodification == false ? 'inputstyle' : 'inputstyles'
              "
              @click="item.ismodification == false ? timeselect() : ''"
            >
              {{ item.info }}
            </div>
            <!-- 自定义时间面板 -->
            <van-action-sheet v-model:show="timeshow">
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="confirm"
                @cancel="timecancel"
              />
            </van-action-sheet>
          </div>
          <!-- icon图标 -->
          <div>
            <van-icon
              style="line-height: 1.2rem"
              name="arrow"
              size="15"
              color="#cecece"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <van-button
        style="width: 90%; margin-left: 5%; margin-top: 0.4rem"
        size="normal"
        :color="
          ismodification == false
            ? 'linear-gradient(to right, #adbcff, #0396ff)'
            : 'linear-gradient(to right, #81fbb8, #28c76f)'
        "
        @click="ismodification == false ? modification() : report()"
      >
        {{ ismodification == false ? "修改信息" : "保存信息" }}
      </van-button>
    </div>
  </div>
</template>
<script>
  // 设置全局背景色
  import color from "@/components/componentsJs/color";
  import { ref, getCurrentInstance, onMounted } from "vue";
  import { Dialog, Toast } from "vant";
  import time from "@/components/componentsJs/time";
  export default {
    setup() {
      color.colormounted();
      // 定义proxy
      const { proxy } = getCurrentInstance();
      let array = proxy.$route.query;
      console.log(array);
      const userinfo = ref();
      userinfo.value = JSON.parse(array.userinfo);
      console.log(userinfo.value);
      // 设定基础值
      const infolist = ref([]);
      const gains = ref(null);
      // 默认颜色
      const colors = ref("color:#cecece");
      // 是否修改
      const ismodification = ref(false);
      // 是否展示弹框
      const Actionshow = ref(false);
      // 时间展示选择
      const timeshow = ref(false);
      // 时间选择得值
      const currentDate = ref(new Date());
      // 时间区间取大值
      const minDate = ref();
      // 时间区间最小值
      const maxDate = ref();
      // 展示内容
      const actions = [
        { name: "男", value: "0" },
        { name: "女", value: "1" },
      ];
      // proxy.$refs.gain.focus();
      const afterRead = (file) => {
        // 此时可以自行将文件上传至服务器
        console.log(file.file);
        const formData = new FormData();
        formData.append("file", item.file);
      };
      // 展示更换属性
      infolist.value = [
        {
          headline: "头像",
          info: "http://www.xinhuanet.com/photo/titlepic/112823/1128234289_1641365131552_title0h.jpg",
          value: "0",
          ismodification: true,
          type: "image",
        },
        {
          headline: "昵称",
          info: userinfo.value.nickName,
          value: "1",
          ismodification: true,
          type: "text",
        },
        {
          headline: "邮箱",
          info: userinfo.value.email,
          value: "2",
          ismodification: true,
          type: "text",
        },
        {
          headline: "性别",
          info: userinfo.value.sex == 1 ? "男" : "女",
          value: "3",
          ismodification: true,
          type: "select",
          labelvalue: userinfo.value.sex,
        },
        {
          headline: "账号",
          info: userinfo.value.phone,
          value: "4",
          ismodification: true,
          type: "text",
        },
        {
          headline: "生日",
          info:
            userinfo.value.birthday.slice(0, -9) +
            " " +
            "00" +
            ":" +
            "00" +
            ":" +
            "00",
          value: "5",
          ismodification: true,
          type: "time",
        },
      ];
      // 打开选项
      function Actionshowclick() {
        console.log("12345678910", Actionshow.value);
        Actionshow.value = true;
      }
      function timeselect() {
        timeshow.value = true;
      }
      // 修改方法
      function modification() {
        colors.value = "color:#333";
        ismodification.value = true;
        infolist.value.forEach((item) => {
          item.ismodification = false;
        });
      }
      // 弹框点击确认方法
      function onCancel() {
        console.log("触发取消方法");
      }
      // 时间弹框取消方法
      function timecancel() {
        console.log("取消");
        timeshow.value = false;
      }
      // 弹框点击取消方法
      function onselect(e) {
        console.log(e, "确认返回值");
        infolist.value[3].info = e.value == "0" ? "男" : "女";
        infolist.value[3].labelvalue = e.value;
      }
      // 时间值返回
      function confirm(e) {
        console.log(time.time(e));
        infolist.value[5].info = time.time(e);
        timeshow.value = false;
      }
      const getRoleSwitchInfo = async (data) => {
        console.log("当前方法");
        let userInfoList = await proxy.$Api.get(
          proxy.$MeApi.getRoleSwitchInfo,
          { params: data }
        );
        if (userInfoList.data.code == 200) {
          userinfo.value = userInfoList.data.data;
          console.log(userinfo.value);
          Toast("保存成功");
          let jsonuserinfo = JSON.stringify(userinfo.value);

          // proxy.$router.push({
          //   path: "me",
          //   query: { userinfo: jsonuserinfo },
          // });
        }
      };
      // 保存方法
      function report() {
        console.log("保存");
        Dialog.confirm({
          title: "标题",
          message: "你确定保存这些信息吗？",
        })
          .then(async () => {
            // 接口
            let data = {
              id: userinfo.value.id,
              sex: infolist.value[3].info == "女" ? 0 : 1,
              phone: infolist.value[4].info,
              birthday: infolist.value[5].info,
              email: infolist.value[2].info,
              nickName: infolist.value[1].info,
              roles: userinfo.value.roles,
              // 头像
              // avatar: "",
            };
            console.log(123);
            let usersave = await proxy.$Api.put(proxy.$MeApi.sysUser, data);
            console.log(usersave.data);
            if (usersave.data.code == 200) {
              colors.value = "color:#cecece";
              ismodification.value = false;
              infolist.value.forEach((item) => {
                item.ismodification = true;
              });
              // 重新获取信息
              let data = {
                roleId: userinfo.value.useRoleId,
                organizeId: userinfo.value.organizeId,
              };
              // 执行方法
              getRoleSwitchInfo(data);
              // let userdata = await proxy.$Api.get(
              //   proxy.$MeApi.getRoleSwitchInfo,
              //   data
              // );
              console.log(userdata.data.data);
            }
          })
          .catch(() => {
            // on cancel
          });
      }
      onMounted(() => {});
      return {
        afterRead,
        infolist,
        ismodification,
        modification,
        gains,
        report,
        colors,
        Actionshow,
        actions,
        onCancel,
        onselect,
        Actionshowclick,
        timeshow,
        currentDate,
        timeselect,
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(2025, 10, 1),
        confirm,
        timecancel,
      };
    },
  };
</script>
<style lang="scss" scoped>
  // 头部组件样式
  .backgroundcolor {
    background-color: #fff;
  }
  @mixin textstyle($size, $font) {
    font-size: $size;
    font-weight: $font;
  }
  .functionstyle {
    width: 100%;
    height: 1.3rem;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 0.2rem;
    @extend .backgroundcolor;
    .list {
      width: 90%;
      font-size: 0.25rem;
      margin: 0 auto;
      margin-top: 0.1rem;
      .textstyles {
        @include textstyle(0.25rem, 600);
        line-height: 1.2rem;
      }
    }
    .commdatastyle {
      margin-right: 0.2rem;
      line-height: 1.2rem;
    }
    .imgstyle {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin-top: 0.1rem;
    }
    .inputstyle {
      width: 3rem;
      height: 100%;
      background-color: #fff;
      border: none;
      text-align: right;
      // direction: rtl;
    }
    .inputstyles {
      width: 3rem;
      height: 100%;
      background-color: #fff;
      border: none;
      text-align: right;
      color: #cecece;
      // direction: rtl;
    }
  }
</style>
