const time = (e) => {
  let time = new Date(e);
  // 年 后续封装
  let newtime = time.getFullYear();
  // 月 后续月
  let newMounth = time.getMonth() + 1;
  // 日 后续日
  let newDay = time.getDate();
  // 时 后续时
  let newHours = time.getHours();
  // 分 后续分
  let newminute = time.getMinutes();
  // 日 后续日
  let newsecond = time.getSeconds();
  console.log("当前时间", newtime + "-" + newMounth + "-" + newDay);
  return newtime + "-" + newMounth + "-" + newDay;
  // 时分秒添加
  // +" " + newHours + ":" + newminute + ":" + newsecond;
};
const Datetimesy = (value) => {
  let date = value ? value : new Date();
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let M = date.getMinutes();
  M = M < 10 ? "0" + M : M;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  var datetime = y + "-" + m + "-" + d;
  return datetime;
};
export default {
  time,
  Datetimesy,
};
